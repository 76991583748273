var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app_header"},[_c('div',{staticClass:"header_pc"},[_c('div',{staticClass:"header_inner"},[_c('div',{staticClass:"header_box clearfix"},[_vm._m(0),_c('div',{staticClass:"right_box"},[_c('ul',{staticClass:"clearfix g_navi",attrs:{"id":"gNavi"}},[_vm._l((_vm.g_navi_list),function(item,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.pageTo(item.href)}}},[_vm._v(_vm._s(item.title))])])}),_c('li',{staticClass:"li_style"},[_c('a',{attrs:{"href":`${_vm.webUrl}regist?pr_vmaf=FRF5hWsogT`,"target":"_blank"}},[_vm._v(" 立即申請 ")])])],2),_c('div',{staticClass:"menu sp",on:{"click":_vm.showNav}},[_vm._m(1)]),_c('div',{staticClass:"menu pad",on:{"click":_vm.showNav}},[_vm._m(2)])])])])]),_c('div',{staticClass:"sp pad"},[_c('div',{staticClass:"cover"}),_c('div',{staticClass:"slider_menu"},[_vm._m(3),_c('div',{staticClass:"box clearfix"},[_vm._m(4),_c('div',{staticClass:"close",on:{"click":_vm.hideNav}},[_vm._m(5)])]),_c('ul',[_vm._l((_vm.g_navi_list),function(item,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.pageTo(item.href)}}},[_vm._v(_vm._s(item.title))])])}),_c('li',[_c('a',{attrs:{"href":`${_vm.webUrl}regist?header=1&pr_vmaf=FRF5hWsogT`,"target":"_blank"}},[_vm._v("申請")])])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('h1',[_vm._v("海外旅行也能快速上網！JCB GLOBAL WiFi")]),_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.jpg"),"alt":"JCB GLOBAL WiFi"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":require("@/assets/images/sp_menu_img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":require("@/assets/images/pad_menu.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s_logo"},[_c('img',{attrs:{"src":require("@/assets/images/menu_img01.jpg"),"alt":"JCB GLOBAL WiFi"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ttl"},[_c('img',{attrs:{"src":require("@/assets/images/menu_img02.png"),"alt":"MENU"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":require("@/assets/images/menu_close.png"),"alt":"關閉"}})])
}]

export { render, staticRenderFns }