import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/layouts/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'index',
        component: () => import("@/views/index"),
        // meta: { //如果需要做路由守卫，则需要加上这个。。。
        //   title: 'JCB GLOBAL WiFi',
        //   requireAuth:true
        // }
      },
      {
        path: 'lp',
        component: () => import("@/views/lp"),
        children: [
          {
            path: 'jcbprivacy',
            name: 'lp_jcbprivacy',
            component: () => import("@/views/lp/jcbprivacy"),
          },
          {
            path: 'jcbagreement',
            name: 'lp_jcbagreement',
            component: () => import("@/views/lp/jcbagreement"),
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
  // 切换路由后滚动条置顶
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//路由守卫---也可放在main.js中
// router.afterEach((to,from,next) => {
  
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
  // setTimeout(function(){
  //   document.body.scrollTop = 0;
  //   document.documentElement.scrollTop = 0;
  // },10)
  // this.$("html, body").animate({ scrollTop: "0" }, 0);
  // document.querySelector('.navbar-area').
  // document.querySelector('.navbar-area').classList.remove("nav__active");

  


// })

export default router
