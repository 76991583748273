import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
  
import { getToken } from '@/utils/cookie'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false




// 路由守卫
router.beforeEach(async(to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title;
  }else {
    document.title = 'JCB GLOBAL WiFi'
  }

  if(to.meta.requireAuth) {
    if(getToken()){
      next();
    }else {
      // next(form.fullPath)
      next('/login')
    }
  }else {
    next();
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
