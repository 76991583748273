<template>
  <div class="app-container">
    <headbar />
    <app-main></app-main>
    <footbar />
  </div>
</template>

<script>
import Headbar from './Headbar';
import AppMain from './AppMain';
import Footbar from './Footbar';
export default {
    name: 'Layout',
    components: {
        Headbar,
        AppMain,
        Footbar
    }
}
</script>

<style>

</style>