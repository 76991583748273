<template>
  <div class="app_footer">
    <div class="sp pad">
      <div class="pageTop" @click="pageTo('#main')"><a href="javascript:;"><img src="@/assets/images/page_top.png" alt=""></a></div>
    </div>
    <div class="footer_pc">
      <div class="footer_box">
        <div class="sub_box clearfix">
          <div class="right_box">
            <img src="@/assets/images/f_imgtext.jpg" class="pc pad">
            <img src="@/assets/images/f_imgtext.jpg" width="100%" alt="" class="sp">
          </div>
          <div class="left_box">
            <ul class="footer_navi">
              <li><a href="/">回首頁</a></li>
              |
              <li><a href="/lp/jcbprivacy">公司簡介</a></li>
              |
              <li><a href="/lp/jcbprivacy#privacy">隱私權保護政策</a></li>
              |
              <li><a href="/lp/jcbprivacy#statement">著作權與網站聲明</a></li>
              |
              <li><a href="/lp/jcbagreement">使用規範</a></li>
            </ul>
          </div>
        </div>
      </div>
      <p>Copyright © <!--?php echo date("Y"); ?--> Vision Mobile Taiwan Inc. All Rights Reserved.</p>
    </div>

  </div>
</template>
<script>
export default {
  data() {
      return {

      }
  },
  methods: {
    pageTo(href) {
        var targetOffset = $(href).offset().top-$('.app_header').height();
        console.log('targetOffset',$(href))
        $('html,body').animate({scrollTop: targetOffset}, 1000);
    },
  }
}
</script>
<style>

</style>
