<template>
  <div class="app-main">
    <transition name="fade-transform" mode="out-in">
        <router-view :key="$route.fullPath" />
    </transition>  
  </div>
</template>

<script>
export default {
  
}
</script>

<style>

</style>