<template>
    <div class="app_header">
        <div class="header_pc">
            <div class="header_inner">
                <div class="header_box clearfix">
                    <div class="left_box">
                        <h1>海外旅行也能快速上網！JCB GLOBAL WiFi</h1>
                        <div class="logo"><a href="/"><img src="@/assets/images/logo.jpg" alt="JCB GLOBAL WiFi"></a></div>
                    </div>
                    <div class="right_box">
                        <!--
                        <p class="pc select-lang">
                            <select class="selected" onchange="location.href = this.options[this.selectedIndex].value">
                                <option value="#">台灣 - 繁體中文</option>
                            </select>
                        </p>
                        -->
                        <ul id="gNavi" class="clearfix g_navi">
                            <li v-for="(item,index) in g_navi_list" :key="index">
                                <a href="javascript:;" @click="pageTo(item.href)">{{ item.title }}</a>
                            </li>
                            <!-- <li><a href="/#a01">特典</a></li>
                            <li><a href="/#a02">JCB GLOBAL WiFi 是什麼</a></li>
                            <li><a href="/#a03">租金</a></li>
                            <li><a href="/#a04">使用流程</a></li> -->
                            <li class="li_style">
                                <a :href="`${webUrl}regist?pr_vmaf=FRF5hWsogT`" target="_blank">
                                    立即申請
                                </a>
                            </li>
                        </ul>
                        <div class="menu sp" @click="showNav"><a href="javascript:;"><img src="@/assets/images/sp_menu_img.png" alt=""></a></div>
                        <div class="menu pad" @click="showNav"><a href="javascript:;"><img src="@/assets/images/pad_menu.png" alt=""></a></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sp pad">
			<div class="cover"></div>
			<div class="slider_menu">
				<div class="s_logo"><img src="@/assets/images/menu_img01.jpg" alt="JCB GLOBAL WiFi"></div>
				<div class="box clearfix">
					<div class="ttl"><img src="@/assets/images/menu_img02.png" alt="MENU"></div>
					<div class="close" @click="hideNav"><a href="javascript:;"><img src="@/assets/images/menu_close.png" alt="關閉"></a></div>
				</div>
				<ul>
                    <li v-for="(item,index) in g_navi_list" :key="index">
                        <a href="javascript:;" @click="pageTo(item.href)">{{ item.title }}</a>
                    </li>
					<li><a :href="`${webUrl}regist?header=1&pr_vmaf=FRF5hWsogT`" target="_blank">申請</a></li>
				</ul>
			</div>
		</div>

    </div>
</template>
<script>
import $ from 'jquery';
export default {
    data() {
        return {
            g_navi_list: [
                {
                    id: 1,
                    href: '#a01',
                    title: '特典'
                },
                {
                    id: 2,
                    href: '#a02',
                    title: 'JCB GLOBAL WiFi 是什麼'
                },
                {
                    id: 3,
                    href: '#a03',
                    title: '租金'
                },
                {
                    id: 4,
                    href: '#a04',
                    title: '使用流程'
                }
            ]
        }
    },
    computed: {
      webUrl() {
        return process.env.VUE_APP_WEB_API
      }
    },
    mounted() {
        // $('a[href*=#]').click(function() {
        //     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
        //         && location.hostname == this.hostname) {
        //             var $target = $(this.hash);
        //             $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        //             if ($target.length) {
        //                 var targetOffset = $target.offset().top-$('#header').height();
        //                 $('html,body').animate({scrollTop: targetOffset}, 1000);
        //                 return false;
        //             }
        //         }
        //     })
    },
    methods: {
        pageTo(href) {
            var targetOffset = $(href).offset().top-$('.app_header').height();
            console.log('targetOffset',$(href))
            $('html,body').animate({scrollTop: targetOffset}, 1000);
        },
        showNav(){
            $('.app-container,.app_header').animate({left: -$('.slider_menu').width()},300);
            $('.slider_menu').animate({right: 0},300,function(){
                // state = 1;
            });
            $('.cover').fadeIn();
            return false;
        },
        hideNav(){
            $('.app-container,.app_header').animate({left: 0},300);
            $('.slider_menu').animate({right: -$('.slider_menu').width()},300,function(){
                // state = 1;
            });
            $('.cover').fadeOut();
            return false;
        }
    }
}
</script>
<style>

</style>
